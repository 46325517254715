"use strict";

const xhr = new XMLHttpRequest();
const parser = new DOMParser();

const getForm = (context = document) => {
  return context.getElementById("contact-form");
};

const getFormContainer = (context = document) => {
  return context.getElementById("container-contact-form");
};

const form = getForm();

const formData = form => {
  let data = {};
  Object.keys(form.elements).forEach(key => {
    let element = form.elements[key];
    if (element.type !== "submit") {
      data[element.name] = element.value;
    }
  });
  return data;
};

const serialize = object => {
  let encodedString = "";
  for (var prop in object) {
    if (Object.prototype.hasOwnProperty.call(object, prop)) {
      if (encodedString.length > 0) {
        encodedString += "&";
      }
      encodedString += encodeURI(prop + "=" + object[prop]);
    }
  }
  return encodedString;
};

if (form) {
  form.addEventListener("submit", e => {
    e.preventDefault();

    xhr.open("POST", form.getAttribute("action"));
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

    xhr.onload = () => {
      if (xhr.status !== 200) {
        console.log("CHYBA");
      } else {
        let parsedHtml = parser.parseFromString(xhr.responseText, "text/html");
        getFormContainer().innerHTML = getFormContainer(parsedHtml).innerHTML;
      }
    };

    xhr.send(serialize(formData(form)));
  });
}
