"use strict";

// tohle nahraje i soubory, ktere nejsou primo linkovane z entrypointu
import "./images/*.{png,jpg,jpeg,gif,svg}";

// UIkit
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";

// loads the Icon plugin
UIkit.use(Icons);

// import stylu
import "./index.scss";

// contact form
import "./scripts/contact-form";

console.log("I am ready!");
//MENU OPEN
document.getElementById("ccollapseToggle").onclick = function() {
  menuManipulate();
};

var submenuCloserButton = document.getElementsByClassName("submenuS");

for (var i = 0; i < submenuCloserButton.length; i++) {
  submenuCloserButton[i].addEventListener("click", menuClose, false);
}

function menuManipulate() {
  const openQ = document.querySelector("#ccollapse");
  if (openQ.classList.contains("open")) {
    //console.log("otevřeno");
    openQ.classList.remove("open");
  } else {
    //console.log("zavřeno");
    openQ.classList.add("open");
  }
}

function menuClose() {
  const openQ = document.querySelector("#ccollapse");
  openQ.classList.remove("open");
}
